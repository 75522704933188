*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.visible {
  visibility: visible;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.bottom-6 {
  bottom: 1.5rem;
}

.left-2\/4 {
  left: 50%;
}

.right-0 {
  right: 0;
}

.right-1\/3 {
  right: 33.3333%;
}

.right-14 {
  right: 3.5rem;
}

.right-8 {
  right: 2rem;
}

.top-2\/4 {
  top: 50%;
}

.top-20 {
  top: 5rem;
}

.top-7 {
  top: 1.75rem;
}

.top-8 {
  top: 2rem;
}

.z-20 {
  z-index: 20;
}

.z-40 {
  z-index: 40;
}

.z-\[-1\] {
  z-index: -1;
}

.m-4 {
  margin: 1rem;
}

.m-auto {
  margin: auto;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.-mt-5 {
  margin-top: -1.25rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.ml-12 {
  margin-left: 3rem;
}

.ml-14 {
  margin-left: 3.5rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.box-border {
  box-sizing: border-box;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-14 {
  height: 3.5rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-\[88\%\] {
  height: 88%;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.w-1\/2 {
  width: 50%;
}

.w-10 {
  width: 2.5rem;
}

.w-10\/12 {
  width: 83.3333%;
}

.w-12 {
  width: 3rem;
}

.w-3\/4 {
  width: 75%;
}

.w-6 {
  width: 1.5rem;
}

.w-9 {
  width: 2.25rem;
}

.w-96 {
  width: 24rem;
}

.w-\[500px\] {
  width: 500px;
}

.w-\[8\.5\%\] {
  width: 8.5%;
}

.w-\[92\%\] {
  width: 92%;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.-translate-x-2\/4 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-2\/4 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes bounce {
  0%, 100% {
    animation-timing-function: cubic-bezier(.8, 0, 1, 1);
    transform: translateY(-25%);
  }

  50% {
    animation-timing-function: cubic-bezier(0, 0, .2, 1);
    transform: none;
  }
}

.animate-bounce {
  animation: 1s infinite bounce;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.animate-fade {
  animation: .5s ease-in-out fadeIn;
}

@keyframes slideright {
  from {
    opacity: 0;
    transform: translateX(-250px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.animate-right {
  animation: .5s ease-in-out slideright;
}

.cursor-pointer {
  cursor: pointer;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-7 {
  gap: 1.75rem;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.rounded {
  border-radius: .25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.border {
  border-width: 1px;
}

.border-4 {
  border-width: 4px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-l {
  border-left-width: 1px;
}

.border-l-2 {
  border-left-width: 2px;
}

.border-green-700 {
  --tw-border-opacity: 1;
  border-color: rgb(21 128 61 / var(--tw-border-opacity));
}

.border-l-\[\#009e66\] {
  --tw-border-opacity: 1;
  border-left-color: rgb(0 158 102 / var(--tw-border-opacity));
}

.bg-\[\#000000dc\] {
  background-color: #000000dc;
}

.bg-\[\#009e66\] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 158 102 / var(--tw-bg-opacity));
}

.bg-\[\#111\] {
  --tw-bg-opacity: 1;
  background-color: rgb(17 17 17 / var(--tw-bg-opacity));
}

.bg-\[\#161616\] {
  --tw-bg-opacity: 1;
  background-color: rgb(22 22 22 / var(--tw-bg-opacity));
}

.bg-\[\#1f1e1e\] {
  --tw-bg-opacity: 1;
  background-color: rgb(31 30 30 / var(--tw-bg-opacity));
}

.bg-\[\#222222\] {
  --tw-bg-opacity: 1;
  background-color: rgb(34 34 34 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.p-1 {
  padding: .25rem;
}

.p-16 {
  padding: 4rem;
}

.p-2 {
  padding: .5rem;
}

.p-20 {
  padding: 5rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-8 {
  padding: 2rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-20 {
  padding-left: 5rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-40 {
  padding-left: 10rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pl-\[283px\] {
  padding-left: 283px;
}

.pr-8 {
  padding-right: 2rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.align-middle {
  vertical-align: middle;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-\[13px\] {
  font-size: 13px;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-extralight {
  font-weight: 200;
}

.font-semibold {
  font-weight: 600;
}

.tracking-widest {
  letter-spacing: .1em;
}

.text-\[\#009e66\] {
  --tw-text-opacity: 1;
  color: rgb(0 158 102 / var(--tw-text-opacity));
}

.text-\[\#9f9f9f\] {
  --tw-text-opacity: 1;
  color: rgb(159 159 159 / var(--tw-text-opacity));
}

.text-\[\#c9c8c8\] {
  --tw-text-opacity: 1;
  color: rgb(201 200 200 / var(--tw-text-opacity));
}

.text-\[\#f0f0f0\] {
  --tw-text-opacity: 1;
  color: rgb(240 240 240 / var(--tw-text-opacity));
}

.text-\[\#ffffff99\] {
  color: #fff9;
}

.text-\[rgb\(159\,159\,159\)\] {
  --tw-text-opacity: 1;
  color: rgb(159 159 159 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.opacity-100 {
  opacity: 1;
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[\#009e66\] {
  --tw-shadow-color: #009e66;
  --tw-shadow: var(--tw-shadow-colored);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-300 {
  transition-duration: .3s;
}

.duration-500 {
  transition-duration: .5s;
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.ease-linear {
  transition-timing-function: linear;
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #161616;
}

::-webkit-scrollbar-thumb {
  background-color: #111;
  border-radius: 15px;
}

html, body {
  height: 100%;
}

body {
  background-color: #111;
}

.before\:absolute:before {
  content: var(--tw-content);
  position: absolute;
}

.before\:left-0:before {
  content: var(--tw-content);
  left: 0;
}

.before\:mt-1:before {
  content: var(--tw-content);
  margin-top: .25rem;
}

.before\:h-4:before {
  content: var(--tw-content);
  height: 1rem;
}

.before\:w-4:before {
  content: var(--tw-content);
  width: 1rem;
}

.before\:bg-\[\#009e66\]:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(0 158 102 / var(--tw-bg-opacity));
}

.after\:absolute:after {
  content: var(--tw-content);
  position: absolute;
}

.after\:right-1:after {
  content: var(--tw-content);
  right: .25rem;
}

.after\:top-1:after {
  content: var(--tw-content);
  top: .25rem;
}

.after\:mt-1:after {
  content: var(--tw-content);
  margin-top: .25rem;
}

.after\:block:after {
  content: var(--tw-content);
  display: block;
}

.after\:h-1:after {
  content: var(--tw-content);
  height: .25rem;
}

.after\:h-4\/5:after {
  content: var(--tw-content);
  height: 80%;
}

.after\:w-4\/5:after {
  content: var(--tw-content);
  width: 80%;
}

.after\:w-full:after {
  content: var(--tw-content);
  width: 100%;
}

.after\:origin-left:after {
  content: var(--tw-content);
  transform-origin: 0;
}

.after\:scale-x-0:after {
  content: var(--tw-content);
  --tw-scale-x: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes ping {
  75%, 100% {
    content: var(--tw-content);
    opacity: 0;
    transform: scale(2);
  }
}

.after\:animate-ping:after {
  content: var(--tw-content);
  animation: 1s cubic-bezier(0, 0, .2, 1) infinite ping;
}

.after\:rounded-full:after {
  content: var(--tw-content);
  border-radius: 9999px;
}

.after\:border-2:after {
  content: var(--tw-content);
  border-width: 2px;
}

.after\:border-\[\#009e66\]:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(0 158 102 / var(--tw-border-opacity));
}

.after\:bg-\[\#009e66\]:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(0 158 102 / var(--tw-bg-opacity));
}

.after\:transition-transform:after {
  content: var(--tw-content);
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.after\:delay-200:after {
  content: var(--tw-content);
  transition-delay: .2s;
}

.after\:ease-linear:after {
  content: var(--tw-content);
  transition-timing-function: linear;
}

.after\:ease-out:after {
  content: var(--tw-content);
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.after\:content-\[\'\'\]:after {
  --tw-content: "";
  content: var(--tw-content);
}

.hover\:scale-105:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:scale-125:hover {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:bg-\[\#0e522b\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(14 82 43 / var(--tw-bg-opacity));
}

.hover\:text-\[\#009e66\]:hover {
  --tw-text-opacity: 1;
  color: rgb(0 158 102 / var(--tw-text-opacity));
}

.hover\:text-\[\#0A66C2\]:hover {
  --tw-text-opacity: 1;
  color: rgb(10 102 194 / var(--tw-text-opacity));
}

.hover\:text-\[\#346b57\]:hover {
  --tw-text-opacity: 1;
  color: rgb(52 107 87 / var(--tw-text-opacity));
}

.hover\:transition-all:hover {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.hover\:after\:scale-x-100:hover:after {
  content: var(--tw-content);
  --tw-scale-x: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:block {
  display: block;
}

@media not all and (width >= 1024px) {
  .max-lg\:w-\[335px\] {
    width: 335px;
  }

  .max-lg\:pb-4 {
    padding-bottom: 1rem;
  }

  .max-lg\:pl-5 {
    padding-left: 1.25rem;
  }

  .max-lg\:pl-8 {
    padding-left: 2rem;
  }

  .max-lg\:pr-8 {
    padding-right: 2rem;
  }

  .max-lg\:pt-4 {
    padding-top: 1rem;
  }
}

@media not all and (width >= 768px) {
  .max-md\:right-6 {
    right: 1.5rem;
  }

  .max-md\:top-4 {
    top: 1rem;
  }

  .max-md\:z-30 {
    z-index: 30;
  }

  .max-md\:m-auto {
    margin: auto;
  }

  .max-md\:mb-4 {
    margin-bottom: 1rem;
  }

  .max-md\:mb-5 {
    margin-bottom: 1.25rem;
  }

  .max-md\:ml-0 {
    margin-left: 0;
  }

  .max-md\:ml-5 {
    margin-left: 1.25rem;
  }

  .max-md\:mt-4 {
    margin-top: 1rem;
  }

  .max-md\:w-24 {
    width: 6rem;
  }

  .max-md\:w-full {
    width: 100%;
  }

  .max-md\:flex-col {
    flex-direction: column;
  }

  .max-md\:justify-center {
    justify-content: center;
  }

  .max-md\:bg-\[\#191919\] {
    --tw-bg-opacity: 1;
    background-color: rgb(25 25 25 / var(--tw-bg-opacity));
  }

  .max-md\:p-0 {
    padding: 0;
  }

  .max-md\:p-2 {
    padding: .5rem;
  }

  .max-md\:p-3 {
    padding: .75rem;
  }

  .max-md\:pl-0 {
    padding-left: 0;
  }

  .max-md\:pt-12 {
    padding-top: 3rem;
  }

  .max-md\:text-center {
    text-align: center;
  }

  .max-md\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .max-md\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .max-md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@media not all and (width >= 640px) {
  .max-sm\:m-auto {
    margin: auto;
  }

  .max-sm\:mt-4 {
    margin-top: 1rem;
  }

  .max-sm\:flex {
    display: flex;
  }

  .max-sm\:w-screen {
    width: 100vw;
  }

  .max-sm\:flex-row {
    flex-direction: row;
  }

  .max-sm\:justify-center {
    justify-content: center;
  }

  .max-sm\:justify-evenly {
    justify-content: space-evenly;
  }

  .max-sm\:break-all {
    word-break: break-all;
  }

  .max-sm\:pl-0 {
    padding-left: 0;
  }

  .max-sm\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

@media (width >= 640px) {
  .sm\:w-screen {
    width: 100vw;
  }

  .sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:justify-evenly {
    justify-content: space-evenly;
  }

  @media not all and (width >= 1024px) {
    .sm\:max-lg\:mr-10 {
      margin-right: 2.5rem;
    }

    .sm\:max-lg\:flex-col {
      flex-direction: column;
    }

    .sm\:max-lg\:text-center {
      text-align: center;
    }
  }
}

@media (width >= 768px) {
  .md\:top-4 {
    top: 1rem;
  }

  .md\:z-auto {
    z-index: auto;
  }

  .md\:ml-14 {
    margin-left: 3.5rem;
  }

  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:w-screen {
    width: 100vw;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:justify-evenly {
    justify-content: space-evenly;
  }

  .md\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .md\:pb-5 {
    padding-bottom: 1.25rem;
  }

  .md\:pl-0 {
    padding-left: 0;
  }

  .md\:opacity-100 {
    opacity: 1;
  }
}

@media (width >= 1024px) {
  .lg\:right-9 {
    right: 2.25rem;
  }

  .lg\:m-3 {
    margin: .75rem;
  }

  .lg\:m-4 {
    margin: 1rem;
  }

  .lg\:w-2\/4 {
    width: 50%;
  }

  .lg\:w-3\/4 {
    width: 75%;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:flex-col {
    flex-direction: column;
  }

  .lg\:p-16 {
    padding: 4rem;
  }

  .lg\:pb-8 {
    padding-bottom: 2rem;
  }

  .lg\:pl-12 {
    padding-left: 3rem;
  }

  .lg\:pl-5 {
    padding-left: 1.25rem;
  }

  .lg\:pl-52 {
    padding-left: 13rem;
  }

  .lg\:pl-9 {
    padding-left: 2.25rem;
  }

  .lg\:pr-10 {
    padding-right: 2.5rem;
  }

  .lg\:pr-12 {
    padding-right: 3rem;
  }

  .lg\:pr-20 {
    padding-right: 5rem;
  }

  .lg\:pt-8 {
    padding-top: 2rem;
  }

  .lg\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
/*# sourceMappingURL=index.177856e1.css.map */
